$(function() {
  $('.async_panel').each(function(_index, item) {
    var registerHandler, requiresClick, worker;
    item = $(item);
    requiresClick = !!item.data('clickable');
    worker = function() {
      item.addClass('processing');
      $('h3', item).hide().show(0);
      return $.ajax({
        url: item.data('url'),
        success: function(data) {
          return $('.panel_contents', item).html(data);
        },
        error: function(data, status, error) {
          return $('.panel_contents', item).html(error);
        },
        complete: function() {
          item.removeClass('processing');
          // Schedule the next request when the current one's completed
          var period = item.data('period');
          if (period) {
            return setTimeout(worker, period * 1000);
          }
        }
      });
    };
    registerHandler = function() {
      item.addClass('clickable');
      return $('h3', item).on('click', function() {
        $('h3', item).off('click');
        item.removeClass('clickable');
        return worker();
      });
    };
    if (requiresClick) {
      return registerHandler();
    } else {
      return worker();
    }
  });
});
