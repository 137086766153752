// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";
import "../stylesheets/async_panel";

import "@activeadmin/activeadmin";
import './async_panel';

// HACK: there's no npm for activeadmin-sortable
import "./activeadmin-sortable";
import "../stylesheets/activeadmin-sortable";
